import { useState, useEffect, useRef } from "react";

export default function Todo() {
  const [item, setItem] = useState("");
  const [itemArray, setItemArray] = useState([]);
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);

  function handleSubmit(e) {
    e.preventDefault();
    if (item === "") {
      alert("empty");
    } else {
      const items = item.split("\n").filter((text) => text !== "");
      const newItems = items.map((text) => ({
        itemName: text,
        isChecked: false,
      }));
      setItemArray([...itemArray, ...newItems]);
      setItem("");
    }
  }

  function handleDelete(e) {
    const confirmDelete = window.confirm("Are you sure you want to delete it?");
    if (confirmDelete) {
      const newArray = [...itemArray];
      newArray.splice(e.target.id, 1);
      setItemArray(newArray);
    }
  }

  function handleCheck(e) {
    const newArray = [...itemArray];
    newArray[e.target.id].isChecked = e.target.checked;
    setItemArray(newArray);
  }
  useEffect(() => {
    const textarea = textareaRef.current;
    textarea.style.height = "";
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, [item]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (itemArray.length > 0) {
        event.preventDefault();
        event.returnValue = "";
        const confirmationMessage =
          "You have unsaved changes. Do you want to download the JSON file before leaving?";
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [itemArray]);

  function handleDownloadJSON() {
    const dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(itemArray, null, 2));
    const link = document.createElement("a");
    link.href = dataStr;
    link.download = "todo-list.json";
    link.click();
  }

  function handleImportJSON(e) {
    e.preventDefault();
    const file = fileInputRef.current?.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = JSON.parse(event.target.result);
        setItemArray(data);
      };
      reader.readAsText(file);
    }
  }
  function handleImportClick() {
    fileInputRef.current.click();
  }

  function handleReset() {
    const confirmed = window.confirm("Are you sure you want to reset it?");
    if (confirmed) {
      setItemArray([]);
    }
  }

  return (
    <div className="Todo">
      <div>
        <h1>To-Do List</h1>
      </div>
      <div className="formDiv">
        <form onSubmit={handleSubmit}>
          <textarea
            ref={textareaRef}
            value={item}
            onChange={(e) => setItem(e.target.value)}
          />
          <button id="add-btn">Add</button>
        </form>
      </div>

      <div>
        {itemArray.length === 0 ? (
          <div className="nothingText">Add your first todo list!</div>
        ) : (
          <div className="tableContainer">
            <table className="todoTable">
              {/* <thead>
              <tr>
                <th>Status</th>
                <th>To-do Item</th>
                <th>Edit</th>
              </tr>
            </thead> */}

              <tbody>
                {itemArray.map((item, index) => (
                  <tr key={index}>
                    <td className="statusHeader">
                      <input
                        type="checkbox"
                        checked={item.isChecked}
                        id={index}
                        onChange={handleCheck}
                      />
                    </td>
                    <td id="testing">
                      <span className={item.isChecked ? "lineThrough" : ""}>
                        {item.itemName}
                      </span>
                    </td>
                    <td className="editHeader">
                      <button
                        id={index}
                        onClick={handleDelete}
                        className="delete-btn">
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <div className="buttonContainer">
        <button id="reset-btn" onClick={handleReset}>
          Reset
        </button>
        <button id="download-btn" onClick={handleDownloadJSON}>
          Download
        </button>
        <button id="import-btn" onClick={handleImportClick}>
          Import
        </button>
        <input
          accept="application/json"
          type="file"
          ref={fileInputRef}
          onChange={handleImportJSON}
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
}
